<template>
  <div class="header is-fixed-el" v-if="getUserInfo?.personalInfo">
    <div class="container">
      <div class="header__inner">
        <div class="header__top">
          <router-link
            :to="{ path: mainBtn.path }"
            class="header__back"
            v-if="!mainBtn.external"
          >
            <div class="header__back-icon">
              <svg class="svg-icon">
                <use xlink:href="#i-check"></use>
              </svg>
            </div>
            {{ mainBtn.name }}
          </router-link>
          <a :href="mainBtn.path" class="header__back" v-else>
            <div class="header__back-icon">
              <svg class="svg-icon">
                <use xlink:href="#i-check"></use>
              </svg>
            </div>
            {{ mainBtn.name }}
          </a>
        </div>
        <div class="header__bottom">
          <div class="header__user-info">
            <div class="header__user-info-name" v-if="getUserInfo?.fullname">
              {{ getUserInfo.fullname }}
            </div>
            <div
              class="header__user-info-name"
              v-if="!getUserInfo?.fullname && getUserInfo?.personalInfo?.msisdn"
            >
              {{ formattedPhone }}
            </div>
            <div
              class="header__user-info-phone"
              v-if="getUserInfo?.fullname && getUserInfo?.personalInfo?.msisdn"
            >
              {{ formattedPhone }}
            </div>
          </div>
          <div class="header__user-logos">
            <img
              v-if="
                ($route.name === 'Оплата заказа' ||
                  $route.name === 'Выбор условий' ||
                  $route.name === 'Способ оплаты' ||
                  $route.name === 'Подтверждение') &&
                (getMobileOperatorType === 4 || getMobileOperatorType === 1)
              "
              class="header__user-logo header__user-occ-logo"
              :src="occLogo"
            />
            <router-link to="/" class="header__user-logo">
              <svg class="svg-icon">
                <use xlink:href="#i-logo"></use>
              </svg>
            </router-link>
          </div>
        </div>
        <div class="header__headline" v-if="title">
          <h1 class="header__headline-title">{{ title }}</h1>
          <div class="header__headline-step">{{ step }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import megafonLogo from "@/assets/img/occ/megafon.png";
import tele2Logo from "@/assets/img/occ/tele2.png";

export default {
  name: "Header",
  computed: {
    ...mapGetters([
      "getUserInfo",
      "getNeedConfirmation",
      "getMobileOperatorType",
    ]),
    formattedPhone() {
      if (this.getUserInfo?.personalInfo?.msisdn) {
        const phone = this.getUserInfo.personalInfo.msisdn.toString();
        return `+${phone.substring(0, 1)} ${phone.substring(
          1,
          4
        )} ${phone.substring(4, 7)}-${phone.substring(7, 9)}-${phone.substring(
          9,
          11
        )}`;
      }
      return "";
    },
    title() {
      if (this.$route.name == "Оплата заказа") {
        return "Оплата заказа";
      }
      if (this.$route.name == "Выбор условий") {
        return "Выбор условий";
      }
      if (this.$route.name == "Способ оплаты") {
        return "Способ оплаты";
      }
      if (this.$route.name == "Подтверждение") {
        return "Подтверждение";
      }
      return "";
    },
    step() {
      if (this.getNeedConfirmation == null) {
        return "";
      }
      let countStep = "2";
      if (this.getNeedConfirmation) {
        countStep = "3";
      }
      if (this.$route.name == "Выбор условий") {
        return `Шаг 1 из ${countStep}`;
      }
      if (this.$route.name == "Способ оплаты") {
        return `Шаг 2 из ${countStep}`;
      }
      if (this.$route.name == "Подтверждение") {
        return `Шаг 3 из ${countStep}`;
      }
      return "";
    },
    mainBtn() {
      const inMarket = {
        name: "В магазин",
        path: this.getUserInfo?.invoice?.returnUrl,
        external: true,
      };
      const inPaymentMethod = {
        name: "Способы оплаты",
        path: "/payment-for-the-order",
        external: false,
      };
      if (
        this.$route.name == "Оплата заказа" ||
        this.$route.name == "Статус оформления" ||
        this.$route.name == "Подтверждение оплаты" ||
        this.$route.name == "Через СБП"
      ) {
        return inMarket;
      }
      if (this.getUserInfo?.invoice?.startPaymentTypeIsUnknown) {
        return inPaymentMethod;
      }
      return inMarket;
    },
    occLogo() {
      return this.getMobileOperatorType === 1 ? megafonLogo : tele2Logo;
    },
  },
};
</script>
