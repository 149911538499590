var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getUserInfo?.personalInfo)?_c('div',{staticClass:"header is-fixed-el"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header__inner"},[_c('div',{staticClass:"header__top"},[(!_vm.mainBtn.external)?_c('router-link',{staticClass:"header__back",attrs:{"to":{ path: _vm.mainBtn.path }}},[_c('div',{staticClass:"header__back-icon"},[_c('svg',{staticClass:"svg-icon"},[_c('use',{attrs:{"xlink:href":"#i-check"}})])]),_vm._v(" "+_vm._s(_vm.mainBtn.name)+" ")]):_c('a',{staticClass:"header__back",attrs:{"href":_vm.mainBtn.path}},[_c('div',{staticClass:"header__back-icon"},[_c('svg',{staticClass:"svg-icon"},[_c('use',{attrs:{"xlink:href":"#i-check"}})])]),_vm._v(" "+_vm._s(_vm.mainBtn.name)+" ")])],1),_c('div',{staticClass:"header__bottom"},[_c('div',{staticClass:"header__user-info"},[(_vm.getUserInfo?.fullname)?_c('div',{staticClass:"header__user-info-name"},[_vm._v(" "+_vm._s(_vm.getUserInfo.fullname)+" ")]):_vm._e(),(!_vm.getUserInfo?.fullname && _vm.getUserInfo?.personalInfo?.msisdn)?_c('div',{staticClass:"header__user-info-name"},[_vm._v(" "+_vm._s(_vm.formattedPhone)+" ")]):_vm._e(),(_vm.getUserInfo?.fullname && _vm.getUserInfo?.personalInfo?.msisdn)?_c('div',{staticClass:"header__user-info-phone"},[_vm._v(" "+_vm._s(_vm.formattedPhone)+" ")]):_vm._e()]),_c('div',{staticClass:"header__user-logos"},[(
              (_vm.$route.name === 'Оплата заказа' ||
                _vm.$route.name === 'Выбор условий' ||
                _vm.$route.name === 'Способ оплаты' ||
                _vm.$route.name === 'Подтверждение') &&
              (_vm.getMobileOperatorType === 4 || _vm.getMobileOperatorType === 1)
            )?_c('img',{staticClass:"header__user-logo header__user-occ-logo",attrs:{"src":_vm.occLogo}}):_vm._e(),_c('router-link',{staticClass:"header__user-logo",attrs:{"to":"/"}},[_c('svg',{staticClass:"svg-icon"},[_c('use',{attrs:{"xlink:href":"#i-logo"}})])])],1)]),(_vm.title)?_c('div',{staticClass:"header__headline"},[_c('h1',{staticClass:"header__headline-title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"header__headline-step"},[_vm._v(_vm._s(_vm.step))])]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }